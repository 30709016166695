import * as React from 'react';
import { graphql } from 'gatsby';
import { useMobile } from '../../../utils/use-series';

import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CLabelList,
  CSectTitle,
  CFadeSlider,
  CFixedImg,
  CSimpleCard,
  LBanquetIncs,
  LVenue,
  LWifi,
  LBanquetContact,
  CSpSliderCard03,
} from '../../../components/_index';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        type="ja"
        data={{
          title: {
            main: '小宴会場「さくら／けやき／いちょう／すずかけ」',
            sub: '宴会棟 4F',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/banquet/4th_floor/kv.png',
              },
              imgSp: {
                src: '/assets/images/banquet/4th_floor/kv__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: '宴会・会議',
                path: '/banquet/',
              },
            ],
            current: {
              label: '小宴会場「さくら／けやき／いちょう／すずかけ」',
            },
          }}
        />
      </CJumbotron>
      <section className="u_mbExlarge l_sect">
        <LWrap>
          <h2 className="c_headingLv2">小宴会場</h2>
          <CLabelList
            data={['会議・コンベンション', '講演・セミナー', 'イベント・展示']}
          />
          <CSpSliderCard03
              exClass="u_mb80"
              col={1}
              data={[
                {
                  img: {
                    src: '/assets/images/banquet/4th_floor/img_sakura04.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/banquet/4th_floor/img_sakura05.png',
                    alt: '',
                  },
                },
              ]}
            />
          <p className="c_sectLead u_mb30">
            少人数のミーティングやセミナーに適した4室の小宴会場は、けやき通りを望む明るく開放的な会場です。
            <br />
            ご来賓の控室としてもご利用いただけます。
          </p>
          {!isSp ? (
            <table className="c_fixedTable">
              <thead>
                <tr>
                  <th rowSpan={2}>スタイル</th>
                  <th rowSpan={2}>広さ（㎡）</th>
                  <th colSpan={2}>人数</th>
                </tr>
                <tr>
                  <th>スクール</th>
                  <th>シアター</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>さくら</th>
                  <td>42</td>
                  <td>18</td>
                  <td>28</td>
                </tr>
                <tr>
                  <th>けやき</th>
                  <td>54</td>
                  <td>27</td>
                  <td>40</td>
                </tr>
                <tr>
                  <th>いちょう</th>
                  <td>59</td>
                  <td>27</td>
                  <td>40</td>
                </tr>
                <tr>
                  <th>すずかけ</th>
                  <td>39</td>
                  <td>12</td>
                  <td>24</td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th rowSpan={2}>会場名</th>
                    <th rowSpan={2}>広さ（㎡）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>さくら</td>
                    <td>42</td>
                  </tr>
                  <tr>
                    <td>けやき</td>
                    <td>54</td>
                  </tr>
                  <tr>
                    <td>いちょう</td>
                    <td>59</td>
                  </tr>
                  <tr>
                    <td>すずかけ</td>
                    <td>39</td>
                  </tr>
                </tbody>
              </table>
              <table className="c_fixedTable">
                <thead>
                  <tr>
                    <th colSpan={3}>人数</th>
                  </tr>
                  <tr>
                    <th>会場名</th>
                    <th>スクール</th>
                    <th>シアター</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>さくら</td>
                    <td>18</td>
                    <td>28</td>
                  </tr>
                  <tr>
                    <td>けやき</td>
                    <td>27</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>いちょう</td>
                    <td>27</td>
                    <td>40</td>
                  </tr>
                  <tr>
                    <td>すずかけ</td>
                    <td>12</td>
                    <td>24</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}
          <CFixedImg
            exClass="u_mbLarge"
            width={1040}
            img={{
              src: '/assets/images/banquet/4th_floor/img_sakura02.png',
              alt: '',
            }}
          />
          <CSectTitle
            title={{
              ja: 'レイアウト例',
              en: (
                <>
                  LAYOUT <br className="u_sp" />
                  EXAMPLE
                </>
              ),
            }}
          />
          <CSimpleCard
            exClass="c_simpleCardUnit__center"
            data={[
              {
                img: {
                  src: '/assets/images/banquet/4th_floor/img_sakura03.png',
                  alt: '',
                },
                text: (
                  <>
                    （左から）
                    <br />
                    すずかけ：向合せ 4名
                    <br />
                    いちょう：スクール 27名
                    <br />
                    けやき：シアター 40名
                    <br />
                    さくら：向合せ 8名
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <LBanquetIncs />
      <LVenue />
      <section className="u_mtMedium u_mbMedium">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
          </ul>
        </LWrap>
      </section>
      <LBanquetContact />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
